import React, { createContext, useContext, useReducer, ReactNode, Dispatch, useEffect } from 'react';
import { ACTION, BOTTOM_BAR_SEVERITY, GENERIC_MODAL_ACTIONS } from './actionTypes';
import { User } from '../types/types';
import { BottomBarState } from '../utils/BottomBar';


interface InitialStateProps {
    user: User | null
    isLoggedIn: Boolean
    isLoading: Boolean
    genericModalAction: { action: GENERIC_MODAL_ACTIONS, props: any } | null
    bottomBar: BottomBarState | null
    isAdminVirteem: Boolean
}

interface Action {
    type: ACTION;
    payload?: any;
}

const initialState: InitialStateProps = {
    user: null,
    isLoggedIn: false,
    isLoading: false,
    genericModalAction: null,
    bottomBar: {
        severity: BOTTOM_BAR_SEVERITY.SUCCESS,
        message: '',
        open: false,
        autoHideDuration: 8000,
    },
    isAdminVirteem: false,
};

const reducer = (state: InitialStateProps, action: Action): InitialStateProps => {
    switch (action.type) {
        case ACTION.LOGOUT:
            return { ...state, user: null, isLoggedIn: false, isLoading: false }
        case ACTION.LOGIN:
            return { ...state, isLoggedIn: true, isLoading: false }
        case ACTION.LOAD_SESSION:
            return {
                ...state,
                user: action.payload.user,
                isLoggedIn: true,
                isLoading: false
            }
        case ACTION.SET_GENERIC_MODAL:
            return { ...state, genericModalAction: action.payload }
        case ACTION.SETBOTTOMBAR:
            return { ...state, bottomBar: { ...state.bottomBar, ...action.payload } }
        case ACTION.SET_IS_VIRTEEM_ADMIN:
            return { ...state, isAdminVirteem: action.payload }
        default:
            return state;
    }
};

const Context = createContext<[InitialStateProps, Dispatch<Action>] | null>(null);

interface ContextProviderProps {
    children: ReactNode;
}

export const ContextProvider: React.FC<ContextProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        console.debug("CONTEXT CHANGED", state)
    }, [state]); // Only re-run the effect if cart changes

    return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

export const useAppContext = (): [InitialStateProps, Dispatch<Action>] => {
    const context = useContext(Context);
    console.debug("THE CONTEXT" , context);
    if (!context) {
        throw new Error('useAppContext must be used within a ContextProvider');
    }
    return context;
};
