import React from 'react';
import './App.css';
import Router from './Router';
import ModalDisplayer from './components/ModalDisplayer';
import BottomBar from './utils/BottomBar';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Router/>
        <ModalDisplayer/> {/* modal dialog boxes) depending on the current action specified in the application context */}
        <BottomBar /> {/* Bottom notification bar - hidden by default - display message when they are something to notice */}
      </header>
    </div>
  );
}

export default App;
