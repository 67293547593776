import { useState } from "react";
import { GENERIC_MODAL_ACTIONS } from "../../../context/actionTypes";
import useManageFeedBacks from "../../../hooks/feedbacks/useManageFeedBacks";
import ListDisplayer from "../../../utils/ListDisplayer";
import { MdOutlineReply } from "react-icons/md";
import { getFaceImage } from "./ReplyFeedbackModal";

const getStatusPoint = (status) => {
    return (
        <div className='flex items-center gap-2'>
            <div className={`rounded-full h-2 w-2 ${status ? 'bg-response-medium' : 'bg-response-positive'}`} />
            {status ? 'En attente' : 'Traité'}
        </div>
    );
}

export default function ManageFeedbacks() {
    const [{ feedbacks }, { loadFeedBacks }] = useManageFeedBacks();
    const [selectedFilters, setSelectedFilters] = useState([]);

    const handleFilterClick = (selectedFace) => {
        setSelectedFilters((prevSelected) =>
            prevSelected.includes(selectedFace)
                ? prevSelected.filter((face) => face !== selectedFace)
                : [...prevSelected, selectedFace]
        );
    };

    const filteredFeedbacks = selectedFilters.length > 0
        ? feedbacks.filter(feedback => selectedFilters.includes(feedback?.data?.face))
        : feedbacks;

    const columnsOnSummary = [
        {
            label: 'Reaction',
            key: 'face',
            colSpan: 1,
        },
        {
            label: 'Avis',
            key: 'content',
            colSpan: 4,
        },
        {
            label: 'Statut',
            key: 'status',
            colSpan: 1,
            className: "truncate",
            template: (data) => (
                <div data-tooltip-id="users-tooltip" data-tooltip-content={data.status ? 'En attente' : 'Traité'}>
                    {getStatusPoint(data.status)}
                </div>
            ),
        },
        // {
        //     label: 'Statut',
        //     key: 'embedding_collection',
        //     colSpan: 2,
        //     template: (data) => <div className='pl-4 text-sm'>{getStatus(data.status)}</div>,
        // },
        {
            label: 'Créé le',
            key: 'created_at',
            type: 'date',
            colSpan: 1,
        },
    ];

    const actionsMenu = [
        {
            action: GENERIC_MODAL_ACTIONS.REPLY_FEEDBACK,
            label: 'Répondre',
            enabled: (e) => true,
            Icon: MdOutlineReply,
        },
    ];

    return (
        <div className="px-8">
            <div className="flex justify-between items-center pb-8">
                <div className='font-bold text-2xl text-black'>Retours</div>
                {/* Boutons de filtrage */}
                <div className="flex gap-4">
                    <div className="relative flex items-center justify-center">
                        <button
                            onClick={() => handleFilterClick('good')}
                            className={`absolute inset-0 w-full h-full rounded-full border-2 ${selectedFilters.includes('good') ? 'border-[#81DA61] shadow-md' : 'border-white'}`}
                            style={{ boxSizing: 'border-box' }}
                        >
                        </button>
                        <img className='w-8 h-8' src='/green.svg' alt="very satisfied" />
                    </div>
                    <div className="relative flex items-center justify-center">
                        <button
                            onClick={() => handleFilterClick('medium')}
                            className={`absolute inset-0 w-full h-full rounded-full border-2 ${selectedFilters.includes('medium') ? 'border-[#FFA552] shadow-md' : 'border-white'}`}
                            style={{ boxSizing: 'border-box' }}
                        >
                        </button>
                        <img className='w-8 h-8' src='/orange.svg' alt="satisfied" />
                    </div>
                    <div className="relative flex items-center justify-center">
                        <button
                            onClick={() => handleFilterClick('bad')}
                            className={`absolute inset-0 w-full h-full rounded-full border-2 ${selectedFilters.includes('bad') ? 'border-[#FC6969] shadow-md' : 'border-white'}`}
                            style={{ boxSizing: 'border-box' }}
                        >
                        </button>
                        <img className='w-8 h-8' src='/red.svg' alt="unsatisfied" />
                    </div>
                    <div className="relative flex items-center justify-center">
                        <button
                            onClick={() => handleFilterClick(null)}
                            className={`absolute inset-0 w-full h-full rounded-full border-2 ${selectedFilters.includes(null) ? 'border-[#919192] shadow-md' : 'border-white'}`}
                            style={{ boxSizing: 'border-box' }}
                        >
                        </button>
                        <img className='w-8 h-8' src='/grey.svg' alt="neutral" />
                    </div>
                </div>
            </div>

            <ListDisplayer
                columnsOnSummary={columnsOnSummary}
                items={filteredFeedbacks.map(feedback => ({ 
                    ...feedback, 
                    face: getFaceImage(feedback?.data?.face), 
                    status: feedback.handled_at === null
                }))}
                actionsMenu={actionsMenu}
                reload={loadFeedBacks}
                defaultSortKey="created_at"
            />
        </div>
    );
}
