import { FaArrowRight } from 'react-icons/fa'
import { CHAT_MODE } from '../../context/actionTypes'
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from 'react-icons/md'
import { twMerge } from 'tailwind-merge'
import { SwitchComponent } from '../../utils/FormComponents'

export default function ContentMenu({ withHistory, toggleHistory, mode, setMode }) {
    return (
        <div className='p-8 space-y-8'>

            <div className=' space-y-2'>
                <div className='font-bold flex items-center gap-1 text-lg'>{'Mode'}</div>
                <div className='text-xs space-y-2'>
                    <div onClick={() => setMode(CHAT_MODE.QUERY)} className={twMerge(`rounded-lg p-2 font-bold flex items-center gap-1 cursor-pointer transform duration-300 border ${mode === CHAT_MODE.QUERY ? ' bg-perso-lightBlue border-perso-lightBlue text-black' : '  bg-transparent border-white text-white'}`)}>
                        {mode === CHAT_MODE.QUERY ? <MdOutlineRadioButtonChecked className='w-4 h-4 mx-2' /> : <MdOutlineRadioButtonUnchecked className='w-4 h-4 mx-2' />}
                        <div>Questions / Réponses</div>
                    </div>
                    <div onClick={() => setMode(CHAT_MODE.SEARCH)} className={twMerge(`rounded-lg text-white p-2 font-bold flex items-center gap-1 cursor-pointer transform duration-300 border ${mode === CHAT_MODE.SEARCH ? ' bg-perso-lightBlue border-perso-lightBlue text-black' : '  bg-transparent border-white text-white'}`)}>
                        {mode === CHAT_MODE.SEARCH ? <MdOutlineRadioButtonChecked className='w-4 h-4 mx-2' /> : <MdOutlineRadioButtonUnchecked className='w-4 h-4 mx-2' />}
                        <div>Recherche dans documents</div>
                    </div>
                </div>
            </div>
            <div className='space-y-2'>
                <div className='font-bold flex items-center gap-2 text-lg'>{'Paramètres'}<div className='rounded-full bg-perso-orange text-black p-1 text-xs'>Beta</div></div>
                <div className='text-sm flex gap-1 items-center'>
                    <SwitchComponent
                        name="is_virteem_admin"
                        checked={withHistory}
                        onChange={toggleHistory}
                    />
                    <div className=' font-bold'>Conserver l'historique</div>
                </div>
            </div>
        </div>
    )
}
