import { useEffect, useRef, useState } from "react"
import { useAppContext } from "../../context/Context"
import api from "../../utils/api"
import { useParams } from "react-router-dom";
import { ACTION, CHAT_MODE, GENERIC_MODAL_ACTIONS, MESSAGE_TYPE } from "../../context/actionTypes";
import { setBottomBarError, setBottomBarWarning } from "../../utils/Functions";

export default function useChat() {
    const n = 2
    const [_, dispatch] = useAppContext()
    const bottomRef = useRef(null)
    const [isLargerThanInitial, setIsLargerThanInitial] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const initialHeight = useRef(null)
    const { id } = useParams();
    const [messages, setMessages] = useState(() => {
        const storedMessages = localStorage.getItem("conversation");
        return storedMessages ? JSON.parse(storedMessages) : [];
    });

    const message = useRef(null)
    const [withHistory, setWithHistory] = useState(() => {
        const storedHistory = localStorage.getItem("keepHistory");
        return storedHistory ? JSON.parse(storedHistory) : false;
    })
    const [mode, setMode] = useState(() => {
        const storedMode = localStorage.getItem("mode");
        return storedMode ? JSON.parse(storedMode) : CHAT_MODE.QUERY;
    })
    const [selectedMessage, setSelectedMessage] = useState(null)
    const [searchResponse, setSearchResponse] = useState(null)
    const [confirmModal, setConfirmModal] = useState(null)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handleMessageChange = () => {
        if (message.current?.value.trim() === '') {
            setIsButtonDisabled(true);
        } else {
            setIsButtonDisabled(false);
        }
    };

    useEffect(() => {
        localStorage.setItem("conversation", JSON.stringify(messages));
        bottomRef?.current?.scrollIntoView({ behavior: 'smooth' })
        setSelectedMessage(messages.slice().reverse().find(message => message?.type === MESSAGE_TYPE.MACHINE))
    }, [messages.length]);

    useEffect(() => {
        localStorage.setItem("keepHistory", JSON.stringify(withHistory));
    }, [withHistory]);

    useEffect(() => {
        localStorage.setItem("mode", JSON.stringify(mode));
    }, [mode]);

    useEffect(() => {
        if (message.current) {
            initialHeight.current = message.current.offsetHeight;
        }
    }, []);

    useEffect(() => {
        bottomRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }, [isLoading])

    const toggleHistory = () => {
        if (withHistory) {
            setWithHistory((old) => !old)
        }
        else {
            setConfirmModal({
                text: "Cette option inclut automatiquement les deux derniers messages dans le prompt.\n\nAttention : Peut provoquer une baisse de précision, il est conseillé de ne pas l'activer.",
                yes: () => (setWithHistory((old) => !old), setConfirmModal(null)),
                no: () => setConfirmModal(null)
            })
        }
    }

    const autoAdaptTextArea = () => {
        if (message) {
            setIsLargerThanInitial(message.current.offsetHeight > initialHeight.current);
        }
        if (message?.current?.value?.length < 3) {
            message.current.style.height = '3.5rem';
        } else {
            message.current.style.height = 'auto';
            message.current.style.height = `${message.current.scrollHeight}px`;
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSend()
        }
        autoAdaptTextArea()
    }

    const send = async (messageToSend, history = []) => {
        try {
            console.log("SENDING", messageToSend, history)
            const response = await api.post(`/request/${mode}/${id}`, {
                query: messageToSend,
                history
            });
            if (response?.data?.detail) {
                setBottomBarWarning(dispatch, response.data.detail)
            }
            if (mode === CHAT_MODE.QUERY) {
                const machineMessage = { type: MESSAGE_TYPE.MACHINE, message: response.data.completion.choices[0].message.content, date: response.data.created, rag: response.data.rag ? Object.values(response.data.rag) : [] };
                return machineMessage
            }
            else {
                return { ...response.data, rag: response.data.rag ? Object.values(response.data.rag) : [] }
            }
        } catch (error) {
            console.log("Error sending message: ", error);
            setBottomBarError(dispatch, error)
        }
    }

    const handleSend = async () => {
        if (message.current.value.trim() === '') return;
        if (isLoading) return
        setIsLoading(true)
        const messageToSend = message.current.value
        if (mode === CHAT_MODE.QUERY) {
            const humanMessage = { type: MESSAGE_TYPE.HUMAN, message: message.current.value, date: new Date().toISOString() };
            const prevMessages = messages
            setMessages((prevMessages) => [...prevMessages, humanMessage]);

            message.current.value = ''
            const history = withHistory ? messages.slice(-n).map(element => ({
                message: element.message,
                sender: element.type
            })) : []
            const aiResponse = await send(messageToSend, history)
            setIsButtonDisabled(true); // Disable the button again after sending
            console.log("RESPONSE", aiResponse)
            if (aiResponse) {
                setMessages(() => [...prevMessages, humanMessage, aiResponse]);
            }
        }
        else {
            const aiResponse = await send(messageToSend)
            setSearchResponse(aiResponse)
        }
        setIsLoading(false);
    };

    const createFeedback = (question, response) => {
        dispatch({
            type: ACTION.SET_GENERIC_MODAL,
            payload: {
                props: { question, response, companyId: id },
                action: GENERIC_MODAL_ACTIONS.CREATE_FEEDBACK
            }
        })
    }

    const handleRetry = async (index) => {
        if (isLoading) return
        setIsLoading(true)
        const updatedMessages = messages.slice(0, index + 1)
        const messageToSend = messages[index].message
        //History with the 2 messages before the current one
        const history = withHistory ? messages.slice(index - n, index).map(element => ({
            message: element.message,
            sender: element.type
        })) : []
        const machineMessage = await send(messageToSend, history)
        setMessages([...updatedMessages, machineMessage])
        setIsLoading(false);
    };

    const deleteConversation = () => {
        setConfirmModal({
            text: "Êtes-vous sûrs de vouloir supprimer la conversation ? Cette action est irréversible.",
            yes: () => (localStorage.removeItem("conversation"), setMessages([]), setConfirmModal(null)),
            no: () => setConfirmModal(null)
        })
    };


    const displayRag = (rag) => {
        dispatch({
            type: ACTION.SET_GENERIC_MODAL,
            payload: {
                action: GENERIC_MODAL_ACTIONS.DISPLAY_RAG,
                props: rag
            }
        })
    }


    return [{
        message,
        messages,
        bottomRef,
        isLargerThanInitial,
        withHistory,
        isLoading,
        mode,
        selectedMessage,
        searchResponse,
        confirmModal
    }, {
        handleKeyDown,
        handleMessageChange,
        autoAdaptTextArea,
        handleSend,
        createFeedback,
        handleRetry,
        setMode,
        setSelectedMessage,
        deleteConversation,
        displayRag,
        setConfirmModal,
        toggleHistory,
        dispatch
    }]
}
