import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const Pagination = ({ page, pages, setPage }) => {
    const isFirstPage = page === 1;
    const isLastPage = page === pages;

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= pages) {
            console.log(`Navigating to page ${newPage}`);
            setPage(newPage); // Assuming setPage is the method to update the current page
        }
    };

    const paginationItems = () => {
        const items = [];
        const visiblePages = 3; // Maximum number of page buttons visible at once
        let startPage = 1;
        let endPage = pages;

        if (pages > visiblePages) {
            const sides = Math.floor(visiblePages / 2);
            startPage = Math.max(page - sides, 1);
            // Correct the calculation for endPage to ensure the range is centered around the current page
            endPage = Math.min(page + sides, pages);

            // Adjust if near the start or end to maintain the total number of pages shown
            if (page < visiblePages - 1) {
                endPage = visiblePages;
            }
            if (page > pages - (visiblePages - 1)) {
                startPage = pages - (visiblePages - 1);
            }

            if (startPage > 1) {
                items.push(<button key="1" className="px-4 py-2 text-sm border hover:bg-gray-100" onClick={() => handlePageChange(1)}>1</button>);
                if (startPage > 2) items.push(<span key="left-ellipsis" className="px-4 py-2 text-sm border">...</span>);
            }

            for (let p = startPage; p <= endPage; p++) {
                items.push(
                    <button key={p.toString()} className={`px-4 py-2 text-sm border ${p === page ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'}`} onClick={() => handlePageChange(p)}>
                        {p}
                    </button>
                );
            }

            if (endPage < pages) {
                if (endPage < pages - 1) items.push(<span key="right-ellipsis" className="px-4 py-2 text-sm border">...</span>);
                items.push(<button key={pages.toString()} className="px-4 py-2 text-sm border hover:bg-gray-100" onClick={() => handlePageChange(pages)}>{pages}</button>);
            }
        } else {
            // Fewer pages than our desired max, just show all
            for (let p = 1; p <= pages; p++) {
                items.push(
                    <button key={p.toString()} className={`px-4 py-2 text-sm border ${p === page ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'}`} onClick={() => handlePageChange(p)}>
                        {p}
                    </button>
                );
            }
        }

        return items;
    };

    return (
        <div className="flex items-center justify-center space-x-2 text-black">
            <button className={`px-4 py-2 text-sm border ${isFirstPage ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100'}`} onClick={() => handlePageChange(page - 1)} disabled={isFirstPage}>
                <FaArrowLeft />
            </button>
            {paginationItems()}
            <button className={`px-4 py-2 text-sm border ${isLastPage ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100'}`} onClick={() => handlePageChange(page + 1)} disabled={isLastPage}>
                <FaArrowRight />
            </button>
        </div>
    );
};

export default Pagination;
