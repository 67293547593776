import { useState } from 'react';
import ButtonMore from './ButtonMore';
import { formatDate, sortDataAlphabetically } from './Functions';
import { twMerge } from 'tailwind-merge';
import { GoChevronUp } from 'react-icons/go';

export default function ListDisplayer({ columnsOnSummary, items, reload = null, actionsMenu = null, defaultSortKey = "name" }) {
    // Calculer la largeur totale de la grille en fonction des colonnes définies
    const totalGridWidth = columnsOnSummary.reduce((total, column) => total + (column.colSpan || 1), 0);
    const gridTemplateColumns = {
        gridTemplateColumns: `repeat(${totalGridWidth}, minmax(0, 1fr))`,
    };

    // État pour gérer la clé de tri et la direction (ascendante ou descendante)
    const [sortKey, setSortKey] = useState({ direction: true, key: defaultSortKey });

    // Fonction pour vérifier et formater le type de données
    const checkType = (type, element) => {
        switch (type) {
            case 'date':
                return formatDate(element);
            default:
                return element;
        }
    };

    return (
        <>
            {/* En-tête de la liste avec les colonnes */}
            <div className='flex bg-gray-700 w-full rounded-t text-lg font-semibold '>
                <div
                    className='flex-grow grid gap-x-1 px-4 h-14'
                    style={gridTemplateColumns}>
                    {columnsOnSummary.map(column => (
                        <div
                            key={column.key}
                            style={{ gridColumn: `span ${column.colSpan || 1}` }}
                            className='flex gap-1 items-center cursor-pointer'
                            onClick={() => setSortKey({ direction: !sortKey.direction, key: column.key })}
                        >
                            {column.label}
                            {/* Ne pas afficher la flèche pour la colonne "Select All" */}
                            {sortKey.key === column.key && column.key !== 'select' && (
                                <GoChevronUp className={`${sortKey.direction && "rotate-180"} transform duration-300`} />
                            )}
                        </div>
                    ))}
                </div>
                {/* Placeholder pour le menu d'actions */}
                {actionsMenu && (
                    <div className='w-10 mr-4 flex items-center justify-center' />
                )}
            </div>

            {/* Corps de la liste */}
            <div className='bg-white flex-grow overflow-y-auto rounded relative text-black'>
                {sortDataAlphabetically(items, sortKey).map((item, index) => (
                    <div key={index} className='flex border-b hover:bg-blue-50 h-14'>
                        <div
                            className='flex-grow grid px-4 gap-x-1 py-3 text-black text-base items-center last:border-none'
                            style={gridTemplateColumns}
                        >
                            {columnsOnSummary.map(column => (
                                <div
                                    key={column.key}
                                    className={twMerge(`${column?.className}`)}
                                    style={{ gridColumn: `span ${column.colSpan || 2}` }}
                                >
                                    {column.template ?
                                        column.template(item, index) :
                                        <div className='truncate'>{checkType(column?.type, item[column.key])}</div>
                                    }
                                </div>
                            ))}
                        </div>
                        {/* Menu d'actions pour chaque ligne */}
                        {actionsMenu && (
                            <div className='w-10 mr-4 flex items-center justify-center'>
                                <ButtonMore
                                    data={{ ...item, reload }}
                                    actionsMenu={actionsMenu}
                                    index={index}
                                    total={items.length}
                                />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
}
