import GenericModal from '../../utils/GenericModal';
import { InputComponent } from '../../utils/FormComponents';
import useSignIn from '../../hooks/useSignIn';
import React from "react";
import {NavLink} from "react-router-dom";
import {twMerge} from "tailwind-merge";

export default function SignIn() {
    const [{ isLoggedIn, signForm }, { handleInputChange, handleSubmit }] = useSignIn()
    return (!isLoggedIn && (
        <GenericModal
            className="w-3/4 lg:w-2/5 xl:w-1/3 2xl:w-1/4 p-4"
            showCloseButton={false}
            exitClick={false}
            blurBackGround={true}
        >
            <div className='space-y-4 lg:space-y-6 py-8 text-black'>
                <div className='text-xl text-center'>Connexion Administrateur</div>
                <form onSubmit={handleSubmit} noValidate className='text-sm space-y-2 lg:space-y-3'>
                    <InputComponent
                        className="my-2"
                        inputClassName='p-3 bg-gray-100'
                        label={`Email\u00a0:`}
                        labelClassName="block text-sm font-medium text-gray-700"
                        name={'email'}
                        type={'text'}
                        value={signForm['email'].value}
                        error={signForm['email'].error ? (`${signForm['email'].error}`) : ''}
                        onChange={handleInputChange}
                    />
                    <InputComponent
                        className="my-2"
                        inputClassName='p-3 bg-gray-100'
                        label={`Mot de passe\u00a0:`}
                        labelClassName="block text-sm font-medium text-gray-700"
                        name={'password'}
                        type={'password'}
                        value={signForm['password'].value}
                        error={signForm['password'].error ? (`${signForm['password'].error}`) : ''}
                        onChange={handleInputChange}
                    />


                    <button type='submit'
                            className='p-3 border border-gray-300 w-full text-center text-sm text-black hover:border-gray-500 rounded transform duration-300'>
                        Connexion
                    </button>
                </form>
                <p className="text-center">
                    <NavLink  to="/reset-pwd"
                                     className={twMerge(`${({isActive}) => (isActive ? 'active' : '')} w-full text-sm underline text-virteem-bluelink hover:text-virteem-bgBlue`)}
                            >Mot de passe oublié ?</NavLink> {/* Link to Home page */}
                </p>
            </div>
        </GenericModal>
        )
    )
}
