import React, { useState, useEffect } from 'react';
import {VscFeedback, VscFiles, VscCommentDiscussion} from 'react-icons/vsc';
import useDashboard from '../../../hooks/dashboard/useDashboard';
import { CustomLineChart } from './CustomLineChart';
import { FaRegUser } from 'react-icons/fa';
import { MdArrowOutward, MdMailOutline } from 'react-icons/md';
import { CustomBarChart } from './CustomBarChart';
import { useAppContext } from '../../../context/Context';
import TimeRangeSelector from './TimeStamp';
import ProgressBar from "../../../utils/ProgressBar";

function getQuotaBarColor(usePercentage): string {
    if (usePercentage < 0.8) {
        return "var(--response-positive-color)";
    } else if (usePercentage < 0.9) {
        return "var(--response-medium-color)";
    } else {
        return "var(--response-negative-color)";
    }
}

export default function Dashboard() {
    const [{ user }, __] = useAppContext();

    // Vérification des données utilisateur
    console.log("User data:", user);

    // Initialisation des dates par défaut
    const today = new Date();
    const defaultStartDate = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
    const defaultEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0];

    const [startDate, setStartDate] = useState(defaultStartDate); // Initialisation avec des dates par défaut
    const [endDate, setEndDate] = useState(defaultEndDate); // Initialisation avec des dates par défaut

    console.log("Initial start date:", startDate);
    console.log("Initial end date:", endDate);

    // États pour la gestion des données du dashboard
    const [{ dataPoints, sale, company_uuid, feedbackStats, planUsage }] = useDashboard(startDate, endDate);


    console.log("Data points:", dataPoints);
    console.log("Sale information:", sale);
    console.log("Company UUID:", company_uuid);
    console.log("Feedback stats:", feedbackStats);
    console.log("Plan usage:", planUsage);

    const handleDateChange = (start, end) => {
        setStartDate(start);
        setEndDate(end);
        console.log("Selected start date:", start);
        console.log("Selected end date:", end);
    };

    if (!user) {
        console.log("User data not loaded, displaying loading state...");
        return <div>Chargement des informations utilisateur...</div>;
    }

    if (!dataPoints || !company_uuid) {
        console.log("Data not ready, displaying loading state...");
        return <div>Chargement des données du tableau de bord...</div>;
    }

    return (
        <div className='px-12'>
            <div className='pb-6 text-black space-y-1'>
                <div className='text-3xl font-semibold'>Bonjour {user.first_name}</div>
                <div className='text-xs font-bold'>Content de vous revoir !</div>
                <TimeRangeSelector onDateChange={handleDateChange} />
            </div>

            {/* Affichage des variables calculées 
            <div className='pb-6'>
                <h2 className='text-2xl font-bold'>Variables calculées concernant les feedbacks</h2>
                <ul className='list-disc pl-5 space-y-2'>
                    <li>Total des feedbacks: {feedbackStats?.totalCountFeedbacks ?? 0}</li>
                    <li>Feedbacks sans note: {feedbackStats?.noGrade ?? 0}</li>
                    <li>Feedbacks positifs: {feedbackStats?.goodGrade ?? 0}</li>
                    <li>Feedbacks moyens: {feedbackStats?.mediumGrade ?? 0}</li>
                    <li>Feedbacks négatifs: {feedbackStats?.badGrade ?? 0}</li>
                    <li>Total des feedbacks traités: {feedbackStats?.totalHandledFeedbacks ?? 0}</li>
                    <li>Pourcentage d'augmentation des feedbacks traités ce mois-ci: {feedbackStats?.percentageIncreaseFeedbacksHandled?.toFixed(2) ?? 0}%</li>
                    <li>Nouveaux feedbacks ce mois-ci: {feedbackStats?.totalNewFeedbacksThisMonth ?? 0}</li>
                </ul>
            </div>*/}

            <div className='pb-6 flex items-stretch gap-8'>
                <div className='w-2/3 pb-4 bg-white p-8 rounded-xl shadow flex flex-col justify-between text-black text-base'>
                    <div className='pb-6'>
                        <div className='font-bold'>Total des retours</div>
                        <div className='opacity-25 font-bold text-sm'>Sur la période</div>
                    </div>
                    <div className='flex gap-6 text-black flex-wrap justify-center'>
                        <div className='w-2/3 pt-8 grow'>
                            {feedbackStats && (
                                <CustomBarChart 
                                    data={{
                                        noGrade: feedbackStats.noGrade,
                                        badGrade: feedbackStats.badGrade,
                                        mediumGrade: feedbackStats.mediumGrade,
                                        goodGrade: feedbackStats.goodGrade
                                    }} 
                                />
                            )}
                        </div>
                        <div className='space-y-5 flex flex-col justify-center'>
                            <div className='flex gap-1 items-center'>
                                <div className='rounded-full h-2 w-2 bg-perso-chartGreen' />
                                <div className='text-xs'>Positifs</div>
                            </div>
                            <div className='flex gap-1 items-center'>
                                <div className='rounded-full h-2 w-2 bg-perso-chartOrange' />
                                <div className='text-xs'>Moyens</div>
                            </div>
                            <div className='flex gap-1 items-center'>
                                <div className='rounded-full h-2 w-2 bg-perso-chartRed' />
                                <div className='text-xs'>Négatifs</div>
                            </div>
                            <div className='flex gap-1 items-center'>
                                <div className='rounded-full h-2 w-2 bg-perso-chartGray' />
                                <div className='text-xs'>Sans avis</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='rounded-xl w-1/3 border p-8 shadow bg-white text-black text-base flex flex-col justify-between min-h-64'>
                    <div className="pb-6">
                        <div className='font-bold'>Total des retours</div>
                        <div className='opacity-25 font-bold text-sm'>Sur la période</div>
                    </div>
                    <div className='space-y-4 grow flex flex-col justify-center'>
                        <div className='flex gap-2 w-full justify-center'>
                            <VscFeedback className='w-12 h-12 text-black' />
                            <div className='text-4xl font-extrabold text-perso-lightBlue'>
                                {feedbackStats?.totalCountFeedbacks ?? 0}
                            </div>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <div className='opacity-25 font-bold text-sm'>Par rapport à la période précédente</div>
                            <div className='text-lg font-bold w-full justify-center flex gap-1 items-center'>
                                <MdArrowOutward
                                    className={`${feedbackStats?.percentageIncreaseCountThisMonth < 0 && 'rotate-180'} w-5 h-5 text-virteem-darkBlueLight`} />
                                <div className="text-perso-blue">
                                    {feedbackStats?.percentageIncreaseCountThisMonth
                                        ? `${parseInt(feedbackStats.percentageIncreaseCountThisMonth).toFixed(0)}%`
                                        : "0%"}
                                </div>
                            </div>
                        </div>
                        <div className='text-base font-bold w-full text-center'>
                            +{feedbackStats?.totalNewFeedbacksThisMonth ?? 0} feedbacks
                        </div>
                    </div>
                </div>
                <div className='rounded-xl w-1/3 border p-8 shadow bg-white text-black text-base flex flex-col justify-between min-h-64'>
                    <div className="pb-6">
                        <div className='font-bold'>Retours traités</div>
                        <div className='opacity-25 font-bold text-sm'>Sur la période</div>
                    </div>
                    <div className='space-y-4 grow flex flex-col justify-center'>
                        <div className='flex gap-2 w-full justify-center'>
                            <VscFeedback className='w-12 h-12 text-black' />
                            <div className='text-4xl font-extrabold text-perso-lightBlue'>
                                {isNaN(feedbackStats?.totalHandledFeedbacks) || isNaN(feedbackStats?.totalCountFeedbacks) || feedbackStats?.totalCountFeedbacks === 0
                                    ? "0%"
                                    : `${(feedbackStats.totalHandledFeedbacks / feedbackStats.totalCountFeedbacks * 100).toFixed(0)}%`}
                            </div>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <div className='opacity-25 font-bold text-sm'>Par rapport à la période précédente</div>
                            <div className='text-lg font-bold w-full justify-center flex gap-1 items-center'>
                                <MdArrowOutward
                                    className={`${feedbackStats?.percentageIncreaseFeedbacksHandled < 0 && 'rotate-180'} w-5 h-5 text-virteem-darkBlueLight`} />
                                <div className="text-perso-blue">
                                    {feedbackStats?.percentageIncreaseFeedbacksHandled
                                        ? `${parseInt(feedbackStats.percentageIncreaseFeedbacksHandled).toFixed(0)}%`
                                        : "0%"}
                                </div>
                            </div>
                        </div>
                        <div className='text-base font-bold w-full text-center'>
                            +{feedbackStats?.totalFeedbacksHandledThisMonth ?? 0} feedbacks traités
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex gap-8 pb-6'>
                <div className='h-80 pb-16 bg-white w-full p-8 rounded-xl shadow flex-col text-base grow'>
                    <div className="pb-6">
                        <div className='font-bold text-black'>Total des questions</div>
                        <div className='opacity-25 font-bold text-black text-sm'>Sur la période</div>
                    </div>
                    <div className="p-2 flex justify-center">
                        <CustomLineChart dataPoints={dataPoints} />
                    </div>
                </div>
                {/*TODO : SCRUM-506*/}
                <div
                    className='rounded-xl w-1/3 border p-8 shadow bg-white text-black text-base flex flex-col justify-between min-h-64'>
                    <div className="pb-6">
                        <div className='font-bold'>Total des quotas</div>
                        <div className='opacity-25 font-bold text-sm'>Sur le mois</div>
                    </div>
                    <div className='space-y-4 grow flex flex-col justify-center gap-2'>
                        <div className='flex flex-col gap-2 w-full justify-center items-center'>
                            <div className='flex w-full justify-center gap-2'>
                                <VscCommentDiscussion className='w-12 h-12 text-black'/>
                                <div className='text-4xl font-extrabold text-perso-lightBlue'>
                                    {isNaN(planUsage?.request_count) || isNaN(planUsage?.plan_request_quota) ?
                                        "0%"
                                        : `${(planUsage.request_count / planUsage.plan_request_quota * 100).toFixed(0)}%`}
                                </div>
                            </div>
                            <div className='opacity-25 font-bold text-black text-sm'>Requêtes exécutées</div>
                        </div>
                        <div className='flex flex-col gap-2 w-full justify-center items-center'>
                            <div className='flex w-full justify-center gap-2'>
                                <VscFiles className='w-12 h-12 text-black'/>
                                <div className='text-4xl font-extrabold text-perso-lightBlue'>
                                    {isNaN(planUsage?.document_usage) || isNaN(planUsage?.plan_document_quota) ?
                                        "0%" : `${(planUsage.document_usage / planUsage.plan_document_quota * 100).toFixed(0)}%`
                                    }
                                </div>
                            </div>
                            <div className='opacity-25 font-bold text-black text-sm'>Volume de documents utilisé</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex gap-8 pb-6">
                {/* TODO : SCRUM-506 */
                    /*<div
                    className='rounded-xl w-1/3 border p-8 shadow bg-white text-black text-base flex flex-col justify-between min-h-64'>
                    <div className="pb-6">
                        <div className='font-bold'>Quota des requêtes exécutées</div>
                        <div className='opacity-25 font-bold text-sm'>Sur le mois</div>
                    </div>
                    <div className='flex w-full justify-center gap-2'>
                        <VscCommentDiscussion className='w-12 h-12 text-black'/>
                        <div className='text-4xl font-extrabold text-perso-lightBlue'>
                            {isNaN(planUsage?.request_count) || isNaN(planUsage?.plan_request_quota) ?
                                "0%"
                                : `${(planUsage.request_count / planUsage.plan_request_quota * 100).toFixed(0)}%`}
                        </div>
                    </div>
                    <div className='flex w-full justify-center gap-2 relative'>
                        <div className='flex w-full justify-center absolute text-xs'>
                            {`${isNaN(planUsage?.request_count) ? 0 : planUsage.request_count} / ${isNaN(planUsage?.plan_request_quota) ? 0 : planUsage.plan_request_quota}`}
                        </div>
                        <div className='absolute'>
                            <ProgressBar
                                progress={isNaN(planUsage?.request_count) ? 0 : planUsage.request_count}
                                total={isNaN(planUsage?.plan_request_quota) ? 0 : planUsage.plan_request_quota}
                                barColor={getQuotaBarColor(isNaN(planUsage?.request_count) || isNaN(planUsage?.plan_request_quota) ?
                                    0
                                    : (planUsage.request_count / planUsage.plan_request_quota))} />
                        </div>
                    </div>
                </div>

                <div
                    className='rounded-xl w-1/3 border p-8 shadow bg-white text-black text-base flex flex-col justify-between min-h-64'>
                    <div className="pb-6">
                        <div className='font-bold'>Quota du volume de documents utilisé</div>
                    </div>
                    <div className='flex w-full justify-center gap-2'>
                        <VscFiles className='w-12 h-12 text-black'/>
                        <div className='text-4xl font-extrabold text-perso-lightBlue'>
                            {isNaN(planUsage?.document_usage) || isNaN(planUsage?.plan_document_quota) ?
                                "0%" : `${(planUsage.document_usage / planUsage.plan_document_quota * 100).toFixed(0)}%`
                            }
                        </div>
                    </div>
                    <div className='flex w-full justify-center gap-2 relative'>
                        <div className='flex w-full justify-center absolute text-xs'>
                            {`${isNaN(planUsage?.document_usage) ? 0 : planUsage.document_usage.toFixed(3)}Mo / ${isNaN(planUsage?.plan_document_quota) ? 0 : planUsage.plan_document_quota}Mo`}
                        </div>
                        <div className='absolute'>
                            <ProgressBar
                                progress={isNaN(planUsage?.document_usage) ? 0 : planUsage.document_usage}
                                total={isNaN(planUsage?.plan_document_quota) ? 0 : planUsage.plan_document_quota}
                                barColor={getQuotaBarColor(isNaN(planUsage?.document_usage) || isNaN(planUsage?.plan_document_quota) ?
                                    0
                                    : (planUsage.document_usage / planUsage.plan_document_quota))}/>
                        </div>
                    </div>
                </div>*/}

                <div
                    className='rounded-xl border p-8 shadow bg-white text-black text-base flex flex-col justify-between w-1/3'>
                    <div className="pb-2">
                        <div className='font-bold'>Votre contact</div>
                        <div className='opacity-25 font-bold text-black text-sm'>Référent Virteem</div>
                    </div>
                    <div className='text-xs font-semibold p-2'>Si vous avez des questions ou si vous avez besoin
                        d'assistance, n'hésitez pas à contacter votre interlocuteur chez Virteem. Nous sommes là pour
                        vous aider !
                    </div>
                    <div className='flex gap-4 items-center flex-wrap justify-center p-2'>
                        <img src='/images/pa.png' className='rounded-full w-20 h-20'/>
                        <div className='space-y-4'>
                            <div className='flex items-center gap-2'>
                                <FaRegUser className='w-3 h-3 text-black'/>
                                <div className='text-xs font-semibold'>{`${sale?.first_name} ${sale?.last_name}`}</div>
                            </div>
                            <div className='flex items-center gap-2'>
                                <MdMailOutline className='w-4 h-4 text-black'/>
                                <a href={`mailto:${sale?.email}`} className='text-xs font-semibold'>{sale?.email}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
