import { AiOutlinePlusCircle } from 'react-icons/ai';
import { FiTrash } from 'react-icons/fi';
import { GENERIC_MODAL_ACTIONS, ACTION } from '../../../context/actionTypes';
import useDocuments from '../../../hooks/files/useDocuments';
import ListDisplayer from '../../../utils/ListDisplayer';
import { humanFileSize } from '../../../utils/Functions';
import { MdOutlineSimCardDownload } from "react-icons/md";
import { IoReload } from 'react-icons/io5';
import { twMerge } from 'tailwind-merge';
import Pagination from '../../../utils/Pagination';
import { ImSpinner2 } from 'react-icons/im';
import { Tooltip } from 'react-tooltip';
import { useState } from 'react';
import { useAppContext } from '../../../context/Context';
import { getLinkFile } from '../../chat/DisplayRagModal';

const getStatusEmoji = (status) => {
    switch (status?.toUpperCase()) {
        case 'PENDING':
        case 'PROCESSING':
        case 'EMBEDDING':
        case 'INDEXING':
        case 'UPLOADING':
        case 'DELETING':
            return <ImSpinner2 className="animate-spin" />;
        case 'PARTIAL':
            return <div className='flex items-center gap-2'><div className='rounded-full bg-response-medium h-2 w-2' />En cours</div>;
        case 'MAINTENANCE':
            return <div className='flex items-center gap-2'><div className='rounded-full bg-response-medium h-2 w-2' />Maintenance</div>;
        case 'DELETED':
        case 'ERROR':
        case 'FAIL':
            return <div className='flex items-center gap-2'><div className='rounded-full bg-response-warning h-2 w-2' />Erreur</div>;
        default:
            return <div className='flex items-center gap-2'><div className='rounded-full bg-response-positive h-2 w-2' />Traité</div>;
    }
};

export default function Documents() {
    const [state, dispatch] = useAppContext();
    console.log("Current state from useAppContext:", state);

    const [
        { documents, search, moreFilters, filters, page, maxPage },
        { createDocument, loadDocuments, setSearch, setMoreFilters, setFilters, setPage }
    ] = useDocuments();

    console.log("Loaded documents:", documents);

    const [selectedDocuments, setSelectedDocuments] = useState([]);

    const handleSelectDocument = (id) => {
        setSelectedDocuments((prev) =>
            prev.includes(id) ? prev.filter((docId) => docId !== id) : [...prev, id]
        );
        console.log("Selected documents after change:", selectedDocuments);
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allDocumentIds = documents.map((doc) => doc.id);
            setSelectedDocuments(allDocumentIds);
            console.log("All documents selected:", allDocumentIds);
        } else {
            setSelectedDocuments([]);
            console.log("Selection cleared.");
        }
    };

    const handleDeleteSelected = (documentId) => {
        let documentsToDelete = selectedDocuments.length > 0 ? selectedDocuments : [documentId];
        console.log("Opening DeleteDocumentModal with IDs:", documentsToDelete);

        dispatch({
            type: ACTION.SET_GENERIC_MODAL,
            payload: {
                action: GENERIC_MODAL_ACTIONS.DELETE_DOCUMENT,
                props: {
                    ids: documentsToDelete,
                    names: documents
                        .filter((doc) => documentsToDelete.includes(doc.id))
                        .map((doc) => doc.name || 'Document sans nom'),
                    reload: () => {
                        loadDocuments();
                        setSelectedDocuments([]); // Clear selection after reload
                    },
                }
            },
        });
    };

    const columnsOnSummary = [
        {
            label: (
                <input
                    type="checkbox"
                    className="checkbox-keep-border"
                    onChange={handleSelectAll}
                    checked={documents.length > 0 && selectedDocuments.length === documents.length}
                />
            ),
            key: 'select',
            colSpan: 1,
            template: (data) => (
                <input
                    type="checkbox"
                    checked={selectedDocuments.includes(data.id)}
                    onChange={() => handleSelectDocument(data.id)}
                />
            ),
        },
        {
            label: 'Nom',
            key: 'name',
            colSpan: 10,
            template: (data) => (data.name ? data.name.toString() : 'N/A'),
        },
        {
            label: 'Statut',
            key: 'embedding_collection',
            colSpan: 2,
            template: (data) => (
                <div data-tooltip-id="documents-tooltip" data-tooltip-content={data.status_message || 'Traité'}>
                    <div className='pl-4'>{getStatusEmoji(data.status)}</div>
                </div>
            ),
        },
        {
            label: 'Taille',
            key: 'int_size',
            colSpan: 1,
            template: (data) => humanFileSize(data.int_size) || 'N/A',
        },
        {
            label: 'Ajouté le',
            key: 'created_at',
            type: 'date',
            colSpan: 2,
            template: (data) => (data.created_at ? new Date(data.created_at).toLocaleDateString() : 'N/A'),
        },
    ];

    const actionsMenu = [
        {
            action: (data) => {
                console.log("Downloading document with ID:", data.id);
                getLinkFile(data.id, dispatch);
            },
            label: 'Télécharger',
            enabled: (e) => true,
            Icon: MdOutlineSimCardDownload,
        },
        {
            action: (data) => handleDeleteSelected(data.id),
            label: 'Supprimer',
            enabled: () => true,
            Icon: FiTrash,
        },
    ];

    return (
        <div className='pt-4 px-4 text-left flex flex-col max-h-screen overflow-hidden text-base'>
            <div className='flex items-center justify-between'>
                <div className='font-bold text-2xl text-black'>Documents</div>
                <div className='flex justify-end gap-2'>
                    <button
                        className="btn-transition bg-perso-blue hover:bg-opacity-75 mb-4"
                        onClick={() => {
                            console.log("Creating a new document");
                            createDocument();
                        }}
                    >
                        <AiOutlinePlusCircle className="h-5 w-5" />
                        <div>Ajouter des fichiers</div>
                    </button>
                    <button
                        className="btn-transition bg-perso-blue hover:bg-opacity-75 mb-4"
                        onClick={() => {
                            console.log("Reloading documents");
                            loadDocuments();
                            setSelectedDocuments([]); // Clear selection after reload
                        }}
                    >
                        <IoReload className="h-5 w-5" />
                        <div>Rafraîchir la liste</div>
                    </button>
                </div>
            </div>
            <ListDisplayer
                columnsOnSummary={columnsOnSummary}
                items={documents}
                reload={loadDocuments}
                actionsMenu={actionsMenu}
                defaultSortKey="created_at"
            />
            {maxPage > 1 && <Pagination page={page} pages={maxPage} setPage={setPage} />}
            <Tooltip
                id="documents-tooltip"
                style={{ fontSize: "0.75rem", zIndex: 9999 }}
                place='left'
            />
        </div>
    );
}
