import React, {useEffect, useState} from "react";
import {User} from "../../../../types/types";
import {useAppContext} from "../../../../context/Context";
import Maintenance from "./Maintenance";
import InfoMessageNote from "../messageNote/InfoMessageNote";

// Mock function to simulate fetching user data from an API
const mockFetchUserData = (): Promise<User> => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                first_name: "Joe",
                last_name: "Doe",
                email: "jo@ht.com",
                status: "active",
                id: 1,
                company_id: 1,
                is_virteem_admin: true,
                company: {
                    name: "Company",
                    email: "comp@ht.com",
                    sector: "tech",
                    status: "active",
                    id: 1,
                    plan_id:  null,
                    salesrep_id: null,
                    uuid: "25ergege",
                    created_at: null},
            });
        }, 1000); // Simulate a delay of 1 second
    });
};
export const Home: React.FC = () => {
    const [{ isAdminVirteem, user }, __] = useAppContext()
    // const [{  user }, __] = useAppContext()
    // let isAdminVirteem = false; //to test when is admin virteem
    console.debug("HOME IS CALLED !!!!");
    console.debug(Home.name, "user =>", user);
    // let user = null; //To test when user is not defined in the context
    return (
        <main className="flex flex-col gap-2">
            { user ? (
                <>
                    <h1>Bienvenue, {user.first_name} {user.last_name}!</h1>
                    <br/>
                    <p>Gérez les informations liées à votre entreprise et à votre compte utilisateur pour que Virteem Companion
                        fonctionne mieux pour vous.</p>
                    <InfoMessageNote display={ isAdminVirteem ? (
                            <p>Vous avez des permissions <strong>administrateurs Virteem</strong>.</p>
                    ) : (
                        <>
                            <p>Vos permissions dans l'application sont  <strong>limités</strong>.</p>
                            <br/>
                            <p>Si vous souhaitez modifier vos permissions, vous devez contacter votre administrateur entreprise.</p>
                        </>
                    )
                    }/>

                </>

            ) : (
                <Maintenance/>
            )}
        </main>
    );
};


export default Home;