import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Main from './components/Main';
import Administration from './components/administration/Administration';
import NotFound from './NotFound';
import ResetPassword from "./components/administration/settings/pwd/ResetPassword";
import Recovery from "./components/administration/settings/pwd/Recovery";

/** Manage application routes.
 * It also includes a RedirectToRoot component that redirects the user to the root (/) when an undefined route is visited.
 *
 * @constructor
 */
export default function Router() {
  return (
      <Routes>
          <Route path="/:id" element={<Main />} />
          <Route path="/administration/*" element={<Administration />} />
          <Route path="/reset-pwd/*" element={<Recovery />} />
          {/* <Route path="*" element={<RedirectToRoot />} /> */}
          <Route path="*" element={<NotFound />} /> {/* Composant affiché pour les routes non définies (page 404). */}
      </Routes>
  );
}

function RedirectToRoot() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/', { replace: true }); //redirect the user to the root immediately after component mounting
  }, [navigate]);

  return null;
}