
import api from '../utils/api';
import { useAppContext } from '../context/Context';
import { useEffect } from 'react';
import { ACTION } from '../context/actionTypes';
import { setBottomBarError } from '../utils/Functions';

//TODO rename to UseAuthenticate
/** Defines the form of the object returned by the useAuthentification hook
 *
 */
interface useAuthenticate {
    state: {};
    actions: {
        logOut: () => Promise<void>;
    };
}

/**
 * requests the api to know if the user is authenticated
 * If authentication fails, it calls setBottomBarError to handle the error.
 * If authentication succeed then :
 *      - dispatch the action load session with data send by the API concerning the user (is_admin, company_id, first_name, last_name)
 *      email, company instance ...)
 *      - if the user is admin Virteem
 * @param dispatch
 */
export const checkToken = async (dispatch) => {
    try {
        const response = await api.get('/user/is_authenticated');
        console.debug("is_authenticated?", response.data)
        // request succeed --> dispatch action load session
        dispatch({
            type: ACTION.LOAD_SESSION,
            payload: { user: response.data }
        });
        console.log("IS ADMIN VIRTEEM", response.data.is_admin, response.data.company_id,  )
        console.log("REZCT ZPP ENV", process.env.REACT_APP_VIRTEEM_ID  )
        //If the user is admin Virteem, dispatch the action set is virteem admin
        if (response.data.is_admin && parseInt(response.data.company_id) === parseInt(process.env.REACT_APP_VIRTEEM_ID)) {
            dispatch({
                type: ACTION.SET_IS_VIRTEEM_ADMIN,
                payload: true
            })
        }
    } catch (err) {
        console.error("Token check error", err);
        setBottomBarError(dispatch, err)
    }
}
/** this hook handles application authentication and automatically verifies user authentication when the component is mounted.
 * It also provides a logout function so that the user can log out.
 * This hook uses the application context to dispatch actions that update the global state according to the results of API calls.
 *
 */
export default function useAuthentication(): [useAuthenticate['state'], useAuthenticate['actions']] {
    const [_, dispatch] = useAppContext()

    useEffect(() => {
        const initUser = async () => {
            checkToken(dispatch);
        };
        initUser();
    }, [dispatch]); //dispatch array ensures that the effect is executed whenever dispatch changes


    /** asynchrone function to disconnect the user
     * if succeed then the action log out is dispacth
     * if failed then set the bottom bar with an error
     */
    async function logOut() {
        try {
            await api.post('/user/logout')
            dispatch({
                type: ACTION.LOGOUT
            })
        } catch (err) {
            setBottomBarError(dispatch, err)
            console.error("Login error", err)
        }
    }
    //return an empty array and the logout action
    return [{
    }, {
        logOut
    }]
}
