import { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../context/Context';
import OutsideAlerter from './OutsideAlerter';
import { MdOutlineMoreHoriz } from 'react-icons/md';
import { ACTION, GENERIC_MODAL_ACTIONS } from '../context/actionTypes';

export default function ButtonMore({ data, actionsMenu, index, total }) {
    const [menuVisible, setMenuVisible] = useState(false);
    const buttonRef = useRef(null);
    const menuRef = useRef(null);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [__, dispatch] = useAppContext();

    useEffect(() => {
        if (menuVisible && menuRef.current) {
            const buttonPosition = buttonRef.current.getBoundingClientRect();
            const menuHeight = menuRef.current.offsetHeight;
            const menuWidth = menuRef.current.offsetWidth;

            const newYPosition = total > 3 && (parseInt(total) - parseInt(index) < 4) ? buttonPosition.top - menuHeight + 44 : buttonPosition.bottom - 44
            const newXPosition = buttonPosition.left - menuWidth - 12

            setMenuPosition({ x: newXPosition, y: newYPosition });
        }
    }, [menuVisible, total, index]);

    const toggleMenu = (e) => {
        e.stopPropagation();
        setMenuVisible(old => !old);
    };

    return (
        <div>
            <OutsideAlerter closeWindow={() => setMenuVisible(false)}>
                <div className="inline-block text-left">
                    <button
                        ref={buttonRef}
                        onClick={toggleMenu}
                        className="text-gray-700 hover:text-gray-900 focus:border-blue-300 outline-none rounded-full hover:bg-opacity-10 bg-opacity-0 bg-black p-2 transform duration-300"
                    >
                        <MdOutlineMoreHoriz className="text-lp-blueMUI" size={24} />
                    </button>
                    {menuVisible && (
                        <div
                            ref={menuRef}
                            style={{ position: 'fixed', top: `${menuPosition.y}px`, left: `${menuPosition.x}px` }}
                            className="z-30 w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden"
                        >
                            {actionsMenu
                                .filter((e) => e.enabled(data))
                                .map((actionObject, actionIndex) => {
                                    const { Icon, action, label } = actionObject;
                                    return (
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if(Object.values(GENERIC_MODAL_ACTIONS).includes(action as GENERIC_MODAL_ACTIONS)){
                                                    dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: { props: data, action } });
                                                    setMenuVisible(!menuVisible);
                                                }
                                                else{
                                                    action(data)
                                                }
                                            }}
                                            key={actionObject.id || actionIndex}
                                            className="flex gap-2 hover:bg-gray-100 w-full p-2"
                                        >
                                            <Icon className="w-5 h-5" />
                                            <div className="whitespace-nowrap text-base">{label}</div>
                                        </button>
                                    );
                                })}
                        </div>
                    )}
                </div>
            </OutsideAlerter>
        </div>
    );
}

