import { useState } from "react"
import { useAppContext } from "../../context/Context"
import { setBottomBarError, setBottomBarSucces } from "../../utils/Functions"
import { ACTION } from "../../context/actionTypes"
import api from "../../utils/api";

export default function useReplyFeedback({ id, reload }) {
    const [_, dispatch] = useAppContext()
    const [response, setResponse] = useState('')

    const handleSubmit = async () => {
        try {
            // Send feedback to backend
            await api.post(`/feedback/response/${id}`, { response });
            dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })
            setBottomBarSucces(dispatch, "Réponse Envoyée !")
            await reload()
        }
        catch (error) {
            setBottomBarError(dispatch, error)
        }
    }

    return [{
        response
    }, {
        setResponse,
        handleSubmit,
        dispatch
    }]
}
