import { useState } from 'react';
import api from '../../utils/api';
import { useAppContext } from '../../context/Context';
import { ACTION } from '../../context/actionTypes';
import { setBottomBarError } from '../../utils/Functions';

export default function useCreateOrUpdateOption({ name, description, price, id, reload }) {
    const [option, setOption] = useState({ name, description, price, id });
    const [errors, setErrors] = useState({} as any);
    const [_, dispatch] = useAppContext();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        // For the 'price' field, ensure the value is converted to a number
        const updatedValue = name === 'price' ? Number(value) : value;
        setOption(prev => ({ ...prev, [name]: updatedValue }));
    };

    const validateForm = () => {
        let curErrors = {};
        // TODO english version
        // if (!option.name) curErrors = { ...curErrors, name: "Option name is required" };
        if (!option.name) curErrors = { ...curErrors, name: "Le nom de l’option est requis" };
        // TODO english version
        // if (!option.price || option.price <= 0) curErrors = { ...curErrors, price: "Option price must be greater than 0" };
        if (!option.price || option.price <= 0) curErrors = { ...curErrors, price: "Le prix de l’option doit être supérieur à 0" };
        return curErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const curErrors = validateForm();

        setErrors(curErrors);

        if (Object.keys(curErrors).length > 0) return;

        try {
            if (id) {
                // Assuming the API endpoint for updating is similar to the provided create endpoint, but using PATCH
                await api.patch(`/option/${id}`, { updated_option: option });
            } else {
                await api.post('/option/', { new_option: option });
            }
            dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null });
            reload();
        } catch (error) {
            console.error("Failed to create/update option", error);
            // TODO english version 
            //setErrors({ submit: "Failed to submit option data." });
            setErrors({ submit: "Échec de soumission des données d’option." });
            setBottomBarError(dispatch, error)
        }
    };

    return [{
        option,
        errors,
    }, {
        dispatch,
        setOption,
        handleInputChange,
        handleSubmit
    }];
}
