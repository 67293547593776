import { useEffect } from 'react';
import { AiOutlineCheckCircle, AiOutlineInfoCircle } from 'react-icons/ai'; //react ia icon
import { BiError } from 'react-icons/bi';
import { twMerge } from 'tailwind-merge';
import { ACTION, BOTTOM_BAR_SEVERITY } from '../context/actionTypes';
import { useAppContext } from '../context/Context';

/** Interface declaration for the state
 *
 */
export interface BottomBarState {
    severity: BOTTOM_BAR_SEVERITY;
    message: string;
    open: boolean;
    autoHideDuration: number;
}

/** Component which display a notification bar in the botton of the screen page.
 *
 * @param className
 * @constructor
 */
const BottomBar = ({ className = '' }) => {
    const [{ bottomBar }, dispatch] = useAppContext();
    const hideMe = () => dispatch({ type: ACTION.SETBOTTOMBAR, payload: { open: false, message: '' } }); //to hide the
    //if button bar is open then a timer is configured to close the notification bar after a given duration called autoHideDuration
    useEffect(() => {
        if (bottomBar?.open) {
            const timer = setTimeout(() => {
                hideMe();
            }, bottomBar?.autoHideDuration);
            return () => clearTimeout(timer);
        }
    }, [bottomBar?.open, bottomBar?.autoHideDuration]);

    /** return an icon according to the severity of the bottom bar
     *
     */
    const getIcon = () => {
        switch (bottomBar?.severity) {
            case BOTTOM_BAR_SEVERITY.ERROR:
                return <BiError className="w-6 h-6 flex-shrink-0" />;
            case BOTTOM_BAR_SEVERITY.SUCCESS:
                return <AiOutlineCheckCircle className="w-6 h-6 flex-shrink-0" />;
            case BOTTOM_BAR_SEVERITY.INFO:
                return <AiOutlineInfoCircle className="w-6 h-6 flex-shrink-0" />;
        }
    };

    //close the bottom bar when the user click on it
    //twMerge allow to merge CSS style
    return (
        <div
            onClick={() => hideMe()}
            className={twMerge(
                `fixed z-[4000] cursor-pointer text-sm flex items-center justify-start gap-2 rounded-lg shadow-lg px-4 py-3 text-white left-1/2 text-center transform -translate-x-1/2  duration-500 bg-gradient-to-br
                ${bottomBar?.open ? 'bottom-8' : '-bottom-32'}
                ${BOTTOM_BAR_SEVERITY.ERROR === bottomBar?.severity ? 'from-red-700 to-red-600' : ''}
                ${BOTTOM_BAR_SEVERITY.SUCCESS === bottomBar?.severity ? 'from-green-700 to-green-600' : ''}
                ${BOTTOM_BAR_SEVERITY.INFO === bottomBar?.severity ? ' from-orange-400 to-orange-500' : ''}
                ${className}`
            )}
        >
            {getIcon()} {/* display the icon according to the severity of the message*/}
            <div className="truncate-lines flex-shrink">{bottomBar?.message}</div>
        </div>
    );
};

export default BottomBar;
