import { useEffect, useState } from 'react';
import { useAppContext } from '../../context/Context';
import { ACTION, GENERIC_MODAL_ACTIONS } from '../../context/actionTypes';
import api from '../../utils/api';
import { setBottomBarError } from '../../utils/Functions';

interface Filter {
    maxSize: number;
    minSize: number;
}
export default function useDocuments() {
    const [_, dispatch] = useAppContext();
    const [documents, setDocuments] = useState([]);
    const [search, setSearch] = useState('');
    const [moreFilters, setMoreFilters] = useState(false);
    const [filters, setFilters] = useState<Filter>({ maxSize: 0, minSize: 0 });
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);

    useEffect(() => {
        loadDocuments();
    }, [page]);

    const loadDocuments = async () => {
        try {
            const response = await api.get(`/document/list?page=${page}&size=10`);
            setDocuments(response.data.items);
            setPage(response.data.page);
            setMaxPage(response.data.pages);
        } catch (error) {
            console.error("Failed to load documents", error);
            setBottomBarError(dispatch, error)
        }
    };

    const createDocument = async () => {
        dispatch({
            type: ACTION.SET_GENERIC_MODAL,
            payload: {
                props: { reload: loadDocuments },
                action: GENERIC_MODAL_ACTIONS.UPLOAD_DOCUMENT
            }
        })
    };

    return [
        { documents, search, moreFilters, filters, page, maxPage },
        { createDocument, loadDocuments, setSearch, setMoreFilters, setFilters, setPage, dispatch }
    ];
}
