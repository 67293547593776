import GenericModal from '../../../utils/GenericModal';
import { ACTION } from '../../../context/actionTypes';
import { InputComponent, TextAreaComponent } from '../../../utils/FormComponents';
import useCreateOrUpdateOption from '../../../hooks/options/useCreateOrUpdateOptions';

const CreateOrUpdateOptionModal = (props) => {
    console.log('CreateOrUpdateOptionModal props:', props);
    const [{
        option,
        errors,
    }, {
        dispatch,
        handleInputChange,
        handleSubmit
    }] = useCreateOrUpdateOption(props);

    return (
        <GenericModal
            className="w-3/4 lg:w-2/5 xl:w-1/2 2xl:w-1/4 text-base text-black rounded-lg"
            showCloseButton={true}
            exitClick={false}
            toggleMe={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })}
        >
            <div className='font-semibold w-full text-left px-4 py-3 border-b text-sm'>Modifier / Ajouter une Option</div>
            <form onSubmit={handleSubmit} noValidate>
                <div className=' p-8'>


                    <div className='grid grid-cols-2 gap-4'>
                        <InputComponent
                            label="Nom:"
                            name="name"
                            value={option.name}
                            onChange={handleInputChange}
                            error={errors.name}
                        />
                        <InputComponent
                            label="Prix:"
                            name="price"
                            value={option.price}
                            onChange={handleInputChange}
                            error={errors.price}
                            type='number'
                        />
                    </div>

                    <TextAreaComponent
                        label="Description:"
                        name="description"
                        value={option.description}
                        onChange={handleInputChange}
                    />
                </div>
                <div className='flex w-full justify-end gap-4 py-2 border-t px-4'>
                    <button onClick={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })} className='rounded border bg-white text-black text-sm font-light py-1 px-2'>
                        Annuler
                    </button>
                    <button type='submit' className='rounded bg-gray-600 hover:bg-gray-700 hover:shadow text-white text-sm py-1 px-2 transform duration-300'>
                        Valider
                    </button>
                </div>
            </form>
        </GenericModal>
    );
};

export default CreateOrUpdateOptionModal;
