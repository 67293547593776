import { ACTION } from "../../../context/actionTypes";
import useCreateOrUpdatePlan from "../../../hooks/plans/useCreateOrUpdatePlan";
import { InputComponent, TextAreaComponent } from "../../../utils/FormComponents";
import GenericModal from "../../../utils/GenericModal";

const CreateOrUpdatePlanModal = (props) => {
    const [{
        plan,
        errors,
    }, {
        dispatch,
        handleInputChange,
        handleSubmit
    }] = useCreateOrUpdatePlan(props);

    // Function to check if the submit button should be disabled
    const isSubmitDisabled = () => {
        const { price, quota_usage, document_limit } = plan;
        return (
            price === '' || price < 0 ||
            quota_usage === '' || quota_usage < 0 ||
            document_limit === '' || document_limit < 0
        );
    };

    return (
        <GenericModal
            className="w-3/4 lg:w-2/5 xl:w-1/2 2xl:w-1/4 text-base text-black rounded-lg"
            showCloseButton={true}
            exitClick={false}
            toggleMe={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })}
        >
            <div className='font-semibold w-full text-left px-4 py-3 border-b text-sm'>Modifier / Ajouter un Plan</div>
            <form onSubmit={handleSubmit} noValidate>
                <div className=' p-8'>
                    <div className='grid grid-cols-2 gap-4'>
                        <InputComponent
                            label="Nom:"
                            name="name"
                            value={plan.name}
                            onChange={handleInputChange}
                            error={errors.name}
                        />
                        <InputComponent
                            label="Quota d'utilisation:"
                            name="quota_usage"
                            value={plan.quota_usage}
                            onChange={handleInputChange}
                            error={errors.quota_usage}
                            type='number'
                        />
                        <InputComponent
                            label="Limite de documents :"
                            name="document_limit"
                            value={plan.document_limit}
                            onChange={handleInputChange}
                            error={errors.document_limit}
                            type='number'
                        />
                        {/* <InputComponent
                            label="Durée (jours) :"
                            name="duration_days"
                            value={plan.duration_days}
                            onChange={handleInputChange}
                            error={errors.duration_days}
                            type='number'
                        /> */}
                        <InputComponent
                            label="Prix (€/mois) :"
                            name="price"
                            value={plan.price}
                            onChange={handleInputChange}
                            error={errors.price}
                            type='number'
                        />
                    </div>

                    <TextAreaComponent
                        label="Description:"
                        name="description"
                        value={plan.description}
                        onChange={handleInputChange}
                    />
                </div>
                <div className='flex w-full justify-end gap-4 py-2 border-t px-4'>
                    <button onClick={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })} className='rounded border bg-white text-black text-sm font-light py-1 px-2'>
                        Annuler
                    </button>
                    <button
                        type='submit'
                        className={`rounded text-white text-sm py-1 px-2 transform duration-300 ${
                            isSubmitDisabled() ? 'bg-gray-400 cursor-not-allowed' : 'bg-gray-600 hover:bg-gray-700 hover:shadow'
                        }`}
                        disabled={isSubmitDisabled()}
                    >
                        Valider
                    </button>
                </div>
            </form>
        </GenericModal>
    );
};

export default CreateOrUpdatePlanModal;
