import React from 'react';
import './Settings.css';
import {Routes, Route, NavLink, Navigate} from 'react-router-dom';
import Home from "./home/Home";
import SignIn from "../SignIn"; // Import the CSS file
import ChangePassword from "./pwd/ChangePassword";
import TitleAndSubTitle from "./TitleAndSubTitle";
import ResetPassword from "./pwd/ResetPassword";
import {twMerge} from "tailwind-merge";



const Settings: React.FC = () => {
    return (
        <main className="main-settings">
            <TitleAndSubTitle h1="Paramètres" h2="Gérez les paramètres de votre compte"/>
            <div className="settings-container">
                {/* Navigation menu with links to Home, Change Password, Reset Password, and Profile pages */}
                <nav className="settings-nav">
                    <ul>
                        <li>
                            <NavLink to="home"
                                     className={twMerge(`${({isActive}) => (isActive ? 'active' : '')} w-full`)}
                                     end>Accueil</NavLink> {/* Link to Home page */}
                        </li>
                        <li>
                            <NavLink to="security"
                                     className={twMerge(`${({isActive}) => (isActive ? 'active' : '')} w-full`)}
                            >Gestion du mot de passe</NavLink> {/* Link to Home page */}
                        </li>
                        <li>
                            <NavLink to="reset"
                                     className={twMerge(`${({isActive}) => (isActive ? 'active' : '')} w-full`)}
                            >Récupération</NavLink> {/* Link to Home page */}
                        </li>
                    </ul>
                </nav>

                {/* Define routes for the settings section */}
                <div className="settings-content">
                    <Routes>
                        {/* eslint-disable-next-line react/jsx-no-undef */}

                        <Route path="home" element={<Home/>}/> {/* Route for Home component */}
                        {/* eslint-disable-next-line react/jsx-no-undef */}
                        <Route path="security" element={<ChangePassword/>}/> {/* Route for change password component */}
                        <Route path="reset" element={<ResetPassword/>}/> {/* Route for change password component */}
                        <Route path="/" element={<Navigate to="/administration/settings/home" />}/> {/* Default route */}
                    </Routes>
                </div>
                <SignIn/>
            </div>
        </main>
    );
}
export default Settings;